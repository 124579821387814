import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

const ContentMenu2 = (cntService) => {
    const { rpdata } = useContext(GlobalDataContext);

    let classAnterior = "md:flex-row-reverse"

    return (
        <>
        <div>
        {
                rpdata?.dbServices?.map((serv, index) => {
                    let classes = index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
                    let nuevaClass = classAnterior === "md:flex-row-reverse" ? "md:flex-row" : "md:flex-row-reverse"
                    classes = nuevaClass
                    classAnterior = classes;
                    
                    return (
                        <div className='pb-20 grid md:grid-cols-2 ' style={{backgroundImage: `url(${serv?.description[0]?.img})`}}>
                            <div key={index} className={`relative overflow-hidden ${classes}`}>
                                <div className='bg-white mb-3 border-2 border-black'>
                                    <h2 className='text-center capitalize'>{serv.name}</h2>
                                </div>
                            </div>
                            <div>
                                {
                                    rpdata?.works?.map((item, index) => {
                                        if (item.service === serv.name) {
                                            return (
                                                <div key={index} className='w-[100%] mx-auto flex items-center border-l-4 mb-4 border-rose-600 bg-white p-2'>
                                                    <p className='w-[75%] md:w-[80%] border-dotted border-b-2 capitalize font-semibold text-[18px] mb-5'>{item.name}</p>
                                                    <p className='w-[25%] md:w-[20%] text-end md:text-start text-[#8E101F] font-semibold'>$ {item.description}</p>
                                                </div>
                                            )
                                        }
                                        return null
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
        </>
    )
  }


export default ContentMenu2
